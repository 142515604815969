import { Link } from "gatsby";
import * as React from "react";
// import { CommentCount } from "gatsby-plugin-disqus";
// import { getDisqusConfig } from "../utils/Blog";
import { Author } from "./Author";
import moment from "moment";
interface Props {
  post: any;
  className?: string;
}

const FeaturedPost = ({ post, className = "featured-post" }: Props) => {
  // const disqusConfig = getDisqusConfig(post)
  const date = moment(post.frontmatter.date, "M/D/YYYY");
  return (
    <div className={`${className} mb-5`}>
      <article className={`blog-post py-6 px-6`}>
        <div className="newest mb-2">NEWEST POST</div>
        <h1 className="is-size-1 mb-3 has-text-weight-medium">
          <Link to={`${post?.fields?.path}`}>{post?.frontmatter?.title}</Link>
        </h1>
        {post?.frontmatter?.category && (
          <div className="meta">
            {post?.frontmatter?.category}
            {" | "}
            {post?.frontmatter?.author}
            {` | `}
            {date.format("MMM DD, YYYY")}
            {/* {` | `}
            <Link to={`/blog/${post.path}#disqus_thread`}>
              <CommentCount config={disqusConfig} placeholder={"..."} />
            </Link> */}
          </div>
        )}
        <div className="pt-4">
          <Link className="button is-spring" to={`${post?.fields?.path}`}>
            Read more
          </Link>
        </div>
      </article>
    </div>
  );
};

export default FeaturedPost;
