import { Link } from "gatsby";
import React from "react";
import { Author } from "./Author";
import { HTMLContent } from "../common/Content";
import moment from "moment";

interface Props {
  post: any;
  isFirst?: boolean;
}

export const Post = ({ post, isFirst = false }: Props) => {
  const date = moment(post.frontmatter.date, "M/D/YYYY");
  return (
    <article className={`blog-post py-6 ${isFirst ? "is-first" : ""}`}>
      <h1 className="is-size-2 mb-5 has-text-weight-medium">
        <Link to={`${post.fields?.path}`}>{post.frontmatter?.title}</Link>
      </h1>
      <div className="meta">
        {post.frontmatter?.category}
        {` | `}
        {post?.frontmatter?.author}
        {` | `}
        {date.format('MMM DD, YYYY')}
      </div>
      <div className="py-3">
        <HTMLContent
          className={"content markdown"}
          content={post?.excerpt}
        ></HTMLContent>
      </div>
      <div className="pt-4">
        <Link className="button is-spring" to={`${post.fields?.path}`}>
          Read more
        </Link>
      </div>
    </article>
  );
};
