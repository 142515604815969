import { Link } from "gatsby";
import React from "react";

export const DOTS = "&hellip;";

interface Props {
  path: string;
  totalCount: number;
  postsPerPage: number;
  currentPage: number;
}

const range = (start: number, end: number) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const getPagination = (
  totalCount: number,
  limit: number,
  siblingCount = 1,
  currentPage: number
): Array<any> => {
  const totalPageCount = Math.ceil(totalCount / limit);
  const totalPageNumbers = siblingCount + 5;
  if (totalPageNumbers >= totalPageCount) {
    return range(1, totalPageCount);
  }
  const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
  const rightSiblingIndex = Math.min(
    currentPage + siblingCount,
    totalPageCount
  );
  const shouldShowLeftDots = leftSiblingIndex > 2;
  const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;
  const firstPageIndex = 1;
  const lastPageIndex = totalPageCount;
  if (!shouldShowLeftDots && shouldShowRightDots) {
    let leftItemCount = 3 + 2 * siblingCount;
    let leftRange = range(1, leftItemCount);
    return [...leftRange, DOTS, totalPageCount];
  }
  if (shouldShowLeftDots && !shouldShowRightDots) {
    let rightItemCount = 3 + 2 * siblingCount;
    let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
    return [firstPageIndex, DOTS, ...rightRange];
  }
  if (shouldShowLeftDots && shouldShowRightDots) {
    let middleRange = range(leftSiblingIndex, rightSiblingIndex);
    return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
  }
  return [];
};

export const Pagination = ({
  path,
  totalCount,
  currentPage,
  postsPerPage,
}: Props) => {
  const range = getPagination(totalCount, postsPerPage, 3, currentPage);

  return (
    <div className="pagination mb-6 pb-6 pt-5">
      <ul className="pagination-list">
        {range.map((page, index) => (
          <>
            <li key={`li-pagination-${index}-page${page}`}>
              {page === DOTS ? (
                <span key={`li-span-${index}`} className="pagination-ellipsis">
                  &hellip;
                </span>
              ) : (
                <Link
                  to={`${path}${page === 1 ? "" : "page-" + page}`}
                  className={`pagination-link ${
                    page === currentPage ? "is-current" : ""
                  }`}
                  key={`link-${index}`}
                >
                  {page}
                </Link>
              )}
            </li>
          </>
        ))}
      </ul>
    </div>
  );
};
