import { graphql } from "gatsby";
import * as React from "react";
import FeaturedPost from "../components/blog/FeaturedPost";
import Layout from "../components/layout/Layout";
import { Post } from "../components/blog/Post";
import { Pagination } from "../components/blog/Pagination";
import highlightCode from "../components/utils/HighlightCode";
import { Head } from "../components/blog/Head";

// props
interface Props {
  data: {
    posts: {
      nodes: Array<{
        id: string;
        fields: {
          slug: string;
        };
        author?: {
          id: string;
        };
        body: {
          internal: {
            content: string;
          };
        };
      }>;
    };
  };
  pageContext: {
    category: string;
    limit: number;
    skip: number;
    numPages: number;
    currentPage: number;
    totalCount: number;
  };
}
// markup
const BlogsTemplate = (props: Props) => {
  React.useEffect(() => {
    highlightCode();
  });
  const posts = props.data.posts.nodes;
  return (
    <Layout className="blog" seo={{ title: "Blog" }}>
      <div className="container">
        <Head />
        {props.pageContext.currentPage < 2 && <FeaturedPost post={posts[0]} />}
        {posts &&
          posts
            .slice(props.pageContext.currentPage < 2 ? 1 : 0)
            .map((post, index: number) => (
              <Post
                isFirst={index === 0}
                key={`article-${index}`}
                post={post}
              />
            ))}
        <Pagination
          path={`/blog/`}
          key={props.pageContext.currentPage}
          currentPage={props.pageContext.currentPage}
          postsPerPage={props.pageContext.limit}
          totalCount={props.pageContext.totalCount}
        />
      </div>
    </Layout>
  );
};

export default BlogsTemplate;

// graphQL Blog posts query
export const pageQuery = graphql`
  query BlogsPage($skip: Int!, $limit: Int!) {
    posts: allMarkdownRemark(
      limit: $limit
      skip: $skip
      filter: { frontmatter: { templateKey: { eq: "blog-template" } } }
    ) {
      nodes {
        excerpt(pruneLength: 300)
        html
        frontmatter {
          title
          slug
          date
          description
          email
          path
          keywords
          author
          email
          category
        }
        fields {
          path
        }
      }
    }
  }
`;
